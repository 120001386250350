import React from 'react'
import styled from 'styled-components'
import { breakpoints, spacing } from '../../utils/constants.js'

import ButtonExternal from '../ButtonExternal'
import SectionTitle from '../SectionTitle'

const ContactContainer = styled.section`
  width: 100%;
`

const ContactDescription = styled.div`
  max-width: 796px;
  margin: 0 auto;
  padding: 0 ${spacing.sm};
  margin-bottom: ${spacing.md};

  @media (max-width: ${breakpoints.mobile}) {
    text-align: center;
  }
`

const ContactBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 796px;
  margin: 0 auto;
  padding: 0 ${spacing.sm};
`

const Contact = () => (
  <ContactContainer>
    <SectionTitle title="Contact" />
    <ContactDescription>
      <p>
        Have a cool project you'd like to submit? Do you have a venue you'd like
        to host an event at? Or are you interested in getting involved with the
        exhibition? <strong>Shoot us an email to get in touch.</strong>
      </p>
    </ContactDescription>
    <ContactBtnContainer>
      <ButtonExternal href="mailto:hello@paradigm.wtf">
        Get
        <br />
        Involved!
      </ButtonExternal>
    </ContactBtnContainer>
  </ContactContainer>
)

export default Contact
