import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { spacing, breakpoints } from '../../utils/constants.js'

import Contact from '../Contact'
import Mark from '../Mark'
import Nav from '../Nav'
import WrongLogo from '../../assets/images/wrong-logo--black.png'
import Instagram from '../Instagram'

const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacing.lg} 0 ${spacing.md} 0;
`

const NavContainer = styled.div`
  margin: ${spacing.sm} 0 ${spacing.md} 0;
`

const SecondaryNavContainer = styled.div`
  display: flex;
`

const HomeAnchor = styled(Link)`
  display: block;
  width: 2.125rem;
  margin: 0 ${spacing.xs};

  @media (min-width: ${breakpoints.mobile}) {
    width: 4.222222222rem;
  }
`

const FooterLink = styled.a`
  display: block;
  width: 2.125rem;
  margin: 0 ${spacing.xs};

  @media (min-width: ${breakpoints.mobile}) {
    width: 4.222222222rem;
  }
`

const WrongLogoMark = styled.img`
  width: 100%;
`

const Footer = () => {
  return (
    <React.Fragment>
      <Contact />
      <FooterContainer>
        <NavContainer>
          <Nav />
        </NavContainer>
        <SecondaryNavContainer>
          <FooterLink href="https://thewrong.org" target="_blank">
            <WrongLogoMark src={WrongLogo} />
          </FooterLink>
          <HomeAnchor to="/">
            <Mark />
          </HomeAnchor>
          <FooterLink
            href="https://www.instagram.com/paradigm.wtf/"
            target="_blank"
          >
            <Instagram />
          </FooterLink>
        </SecondaryNavContainer>
      </FooterContainer>
    </React.Fragment>
  )
}
export default Footer
