import styled from 'styled-components'
import { breakpoints, spacing } from '../../utils/constants'

export default styled.section`
  display: flex;
  flex-wrap: wrap;
  ${props => (props.displayColmun ? 'flex-direction: column;' : null)}
  ${props => (props.fullScreen ? 'min-height: 100vh;' : null)}
  ${props => (props.centered ? 'align-items: center;' : null)}
  ${props => (props.centered ? 'justify-content: center;' : null)}
  max-width: 1200px;
  margin: 0 auto;
  ${props => (props.fullScreen ? `margin-top: -${spacing.lg};` : null)}
  padding: 0 ${spacing.sm};

  @media (max-width: ${breakpoints.mobile}) {
    ${props => (props.fullScreen ? 'min-height: 100vh;' : null)}
    ${props => (props.fullScreen ? `margin-top: -${spacing.md};` : null)}
  }
`
