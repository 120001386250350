import React from 'react'
import styled from 'styled-components'

import { breakpoints, spacing, color } from '../../utils/constants'

const TitleContainer = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
`

const Title = styled.h2`
  line-height: 0.8;
  padding: ${spacing.sm};
  border: 8px solid ${color.baseDark};

  @media (max-width: ${breakpoints.mobile}) {
    border: 5px solid ${color.baseDark};
    padding: ${spacing.xs};
  }
`

export default function SectionTitle(props) {
  return (
    <TitleContainer>
      <Title>{props.title}</Title>
    </TitleContainer>
  )
}
