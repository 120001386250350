import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { font, color } from '../../utils/constants.js'

const NavLinks = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const NavLink = styled(Link)`
  font-family: ${font.black};
  font-size: 1.333333333rem;
  padding: 0 1rem;
  text-decoration: none;
  color: ${color.baseDark};
  transition: color 0.2s ease-out, background-color 0.2s ease-out;

  &:hover {
    color: ${color.baseLight};
    background-color: ${color.baseDark};
  }
`

const InnactivePage = styled.p`
  position: relative;
  display: block;
  margin: 0;
  margin: 0 1rem;
  font-family: ${font.black};
  font-size: 1.333333333rem;
  color: ${color.baseGrey};

  &:after {
    content: ' ';
    position: absolute;
    top: 1.1rem;
    left: 0;
    display: block;
    width: 100%;
    height: 4px;
    background-color: ${color.baseGrey};
  }
`

export default function Nav(props) {
  return (
    <NavLinks>
      <NavLink
        to="/what"
        activeStyle={{ backgroundColor: '#000', color: '#FFF' }}
      >
        What
      </NavLink>
      <NavLink
        to="/projects"
        activeStyle={{ backgroundColor: '#000', color: '#FFF' }}
      >
        Projects
      </NavLink>
      <InnactivePage>Events</InnactivePage>
    </NavLinks>
  )
}
