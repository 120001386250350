import React from 'react'
import styled from 'styled-components'

const Logo = styled.svg`
  width: 100%;
`

export default function Mark() {
  return (
    <Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104 104">
      <g fillRule="evenodd">
        <path d="M51.5234,5.9998 C26.4214,5.9998 6.0004,26.4218 6.0004,51.5228 C6.0004,76.6248 26.4214,97.0458 51.5234,97.0458 C76.6254,97.0458 97.0464,76.6248 97.0464,51.5228 C97.0464,26.4218 76.6254,5.9998 51.5234,5.9998 M51.5234,103.0458 C23.1134,103.0458 0.0004,79.9328 0.0004,51.5228 C0.0004,23.1128 23.1134,-0.0002 51.5234,-0.0002 C79.9334,-0.0002 103.0464,23.1128 103.0464,51.5228 C103.0464,79.9328 79.9334,103.0458 51.5234,103.0458" />
        <path d="M24.0186,40.0077 L51.5126,82.4547 L79.0266,40.0087 L51.5056,22.6257 L24.0186,40.0077 Z M51.5116,90.9727 L51.5106,90.9727 C50.4946,90.9717 49.5466,90.4567 48.9946,89.6037 L17.3336,40.7237 C16.8986,40.0507 16.7496,39.2327 16.9206,38.4487 C17.0926,37.6667 17.5716,36.9857 18.2476,36.5577 L49.9016,16.5407 C50.8796,15.9217 52.1276,15.9227 53.1066,16.5407 L84.7966,36.5567 C85.4756,36.9847 85.9536,37.6657 86.1246,38.4487 C86.2976,39.2327 86.1486,40.0517 85.7126,40.7247 L54.0296,89.6047 C53.4756,90.4567 52.5286,90.9727 51.5116,90.9727 L51.5116,90.9727 Z" />
        <path d="M24.0195,67.0399 L51.5405,84.4229 L79.0285,67.0409 L51.5345,24.5929 L24.0195,67.0399 Z M51.5415,90.9719 C50.9855,90.9719 50.4295,90.8169 49.9405,90.5079 L18.2485,70.4919 C17.5705,70.0639 17.0925,69.3819 16.9205,68.5999 C16.7485,67.8159 16.8975,66.9969 17.3325,66.3239 L49.0175,17.4439 C49.5715,16.5909 50.5185,16.0759 51.5355,16.0759 L51.5365,16.0759 C52.5525,16.0759 53.4995,16.5919 54.0525,17.4449 L85.7125,66.3239 C86.1485,66.9969 86.2965,67.8159 86.1255,68.5989 C85.9545,69.3809 85.4755,70.0619 84.7985,70.4909 L53.1455,90.5079 C52.6565,90.8169 52.0985,90.9719 51.5415,90.9719 L51.5415,90.9719 Z" />
      </g>
    </Logo>
  )
}
