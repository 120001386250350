import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { layer } from '../../utils/constants.js'

import PrimaryNav from '../PrimaryNav'

const HeaderContainer = styled.header`
  position: fixed;
  width: 100%;
  z-index: ${layer.top};
`

const Header = ({ siteTitle }) => {
  return (
    <HeaderContainer>
      <PrimaryNav />
    </HeaderContainer>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
