import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import MenuButton from '../MenuButton'
import { font, color, spacing, breakpoints } from '../../utils/constants.js'

const PrimaryNavContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: ${props => (props.menuActive ? '100vh' : 'auto')};
  padding-top: ${spacing.sm};
  background-color: ${props =>
    props.menuActive ? color.navBG : 'transparent'};
  backdrop-filter: ${props => (props.menuActive ? 'blur(12px)' : 'none')};

  @media (max-width: ${breakpoints.mobile}) {
    padding-top: ${spacing.xs};
  }
`
const PrimaryMenu = styled.nav`
  display: ${props => (props.menuActive ? 'block' : 'none')};
  width: 19rem;
  padding-right: ${spacing.sm};

  @media (max-width: ${breakpoints.mobile}) {
    width: 15rem;
    padding-top: 0.625rem;
  }
`

const PrimaryHomeAnchor = styled(Link)`
  display: block;
  font-family: ${font.black};
  font-size: 2.666666667rem;
  text-decoration: none;
  color: ${color.baseDark};
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: rotate(-12deg);
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 2rem;
  }
`

const MenuLink = styled(Link)`
  position: relative;
  display: block;
  padding-left: 4rem;
  font-family: ${font.light};
  font-size: 3.555555556rem;
  text-decoration: none;
  color: ${color.baseDark};
  transition: padding-left 0.3s ease-in-out;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    width: 3.9rem;
    height: 0.333333333rem;
    background-color: ${color.baseDark};
    transition: width 0.3s ease-in-out;
  }

  &:hover {
    padding-left: 6rem;

    &:before {
      width: 5.9rem;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 2.875rem;

    &:before {
      height: 0.25rem;
    }
  }

  &.active {
    padding-left: 6rem;

    &:before {
      width: 5.9rem;
    }
  }
`

const InnactiveMenuLink = styled.p`
  position: relative;
  display: block;
  margin: 0;
  padding-left: 4rem;
  font-family: ${font.light};
  font-size: 3.555555556rem;
  color: ${color.baseGrey};

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 50%;
    display: block;
    width: 100%;
    height: 0.333333333rem;
    background-color: ${color.baseGrey};
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 2.875rem;
    &:before {
      height: 0.25rem;
    }
  }
`

export default function PrimaryNav(props) {
  const [isMenuOpen, setMenu] = useState(false)

  return (
    <PrimaryNavContainer menuActive={isMenuOpen}>
      <MenuButton onClick={() => setMenu(!isMenuOpen)} menuActive={isMenuOpen}>
        {isMenuOpen ? 'Close' : 'Menu'}
      </MenuButton>
      <PrimaryMenu menuActive={isMenuOpen}>
        <PrimaryHomeAnchor to="/" onClick={() => setMenu(!isMenuOpen)}>
          Paradigm
        </PrimaryHomeAnchor>
        <MenuLink
          to="/what"
          activeClassName="active"
          onClick={() => setMenu(!isMenuOpen)}
        >
          What
        </MenuLink>
        <MenuLink
          to="/projects"
          activeClassName="active"
          onClick={() => setMenu(!isMenuOpen)}
        >
          Projects
        </MenuLink>
        <InnactiveMenuLink>Events</InnactiveMenuLink>
      </PrimaryMenu>
    </PrimaryNavContainer>
  )
}
