const type = {
  baseSize: '1.666666667rem',
  smaller: '0.888888889rem',
}

const font = {
  light: "'Moderat Light', 'Helvetica', 'Arial', sans-serif",
  regular: "'Moderat Regular', 'Helvetica', 'Arial', sans-serif",
  black: "'Moderat Black', 'Helvetica', 'Arial', sans-serif",
}

const spacing = {
  xs: '0.888888889rem',
  sm: '1.777777778rem',
  md: '3.111111111rem',
  lg: '4.888888889rem',
  xl: '10.222222222rem',
}

const breakpoints = {
  saba: '320px',
  mobile: '500px',
  tablet: '800px',
  desktop: '1200px',
  desktopHD: '1440px',
}

const color = {
  baseDark: '#000000',
  baseLight: '#FFFFFF',
  baseGrey: '#919191',
  navBG: 'rgba(255,255,255,0.9)',
}

const layer = {
  bottom: '-100',
  base: '0',
  top: '100',
  space: '500',
}

export { type, font, spacing, breakpoints, color, layer }
