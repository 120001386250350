import styled from 'styled-components'
import { spacing, font, color, layer, breakpoints } from '../../utils/constants'

const MenuButton = styled.button`
  width: 4.444444444rem;
  height: 4.444444444rem;
  margin-right: ${spacing.sm};
  text-align: center;
  border-radius: 50%;
  font-family: ${font.black};
  font-size: 1rem;
  letter-spacing: 1.25px;
  border: 4px solid ${color.baseDark};
  color: ${props => (props.menuActive ? color.baseLight : color.baseDark)};
  background-color: ${props =>
    props.menuActive ? color.baseDark : color.baseLight};
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease-in-out;
  transform: rotate(-30deg);
  z-index: ${layer.space};

  &:hover {
    transform: rotate(0deg);
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 0.8rem;
  }
`
export default MenuButton
