/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import 'modern-normalize/modern-normalize.css'
import Header from '../Header'
import Footer from '../Footer'
import './index.css'

import { spacing, breakpoints } from '../../utils/constants'
const PageWrap = styled.main`
  padding-top: ${props => (props.noTopPadding ? '0' : `${spacing.lg}`)};
  @media (max-width: ${breakpoints.mobile}) {
    padding-top: ${props => (props.noTopPadding ? '0' : `${spacing.md}`)};
  }
`

const Layout = ({ children, hideFooter, noTopPadding }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  if (noTopPadding === undefined) {
    noTopPadding = false
  }

  return (
    <React.Fragment>
      <Header siteTitle={data.site.siteMetadata.title} />
      <PageWrap noTopPadding={noTopPadding}>{children}</PageWrap>
      {!hideFooter && <Footer />}
    </React.Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
